<template>
  <div style="overflow: hidden" class="main">
    <div class="top-wrap">
      <div class="top">
        <div class="max-1152">
          <div class="title">
            <img class="all-course l" src="https://www.imooc.com/static/img/course/v4/course-title2.png" alt="title"/>
            <div id="courseIndexBanner" style="display: block"><img src="https://www.imooc.com/static/img/course/course-top.png?v=2" style="cursor: default"/></div>
          </div>
          <div class="course-top-search">
            <div class="search-area" data-search="top-banner">
              <input class="search-input"  v-model="search" data-suggest-trigger="suggest-trigger" placeholder="搜索感兴趣的免费课内容" type="text" autocomplete="off"/>
              <ul class="search-area-result js-search-wrap" data-suggest-result="suggest-result" style="display: none"></ul>
            </div>
          </div>
        </div>
      </div>
      <div class="filter">
        <div  class="max-1152">
          <div class="direction js-direction" style="
              box-shadow: rgba(95, 101, 105, 0) 0px 12px 20px 0px;
              height: 32px;
              background: transparent;
            ">
            <span class="name">方向：</span>
            <ul class="items">
              <li class="item" @click="fxactive = v" :class="{ on: fxactive == v }" :key="i" v-for="(v, i) in fangxiang">
                <a href="javascript:void(0)">{{ v.name }}</a>
              </li>
            </ul>
          </div>
          <div class="sort js-sort" style="
              box-shadow: rgba(95, 101, 105, 0) 0px 12px 20px 0px;
              height: 32px;
              background: transparent;
            ">
            <span class="name">分类：</span>
            <ul class="items">
              <li :class="{ on: FLactive.name == '全部' }" @click="FLactive = '全部'" class="item"><a href="javascript:void(0)">全部</a></li>
              <li :class="{ on: FLactive == v }" class="item" @click="FLactive = v" v-for="(v, i) in classFL" :key="i">
                <a href="javascript:void(0)">{{ v.name }}</a>
              </li>
            </ul>
          </div>
          <div class="level">
            <span class="name">难度：</span>
            <ul class="items">
              <li :class="{ on: ndActive == v }" class="item" @click="ndActive = v" v-for="(v, i) in nd" :key="i">
                <a href="javascript:void(0)">{{ v }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div  class="course-type">
        <div  class="max-1152">
          <ul  class="items">
            <li class="item on" data-type="all">
              <a href="">全部</a>
            </li>
          </ul>
          <div class="recommend js-recommend">
            <i class="imv2-kecheng1"></i>
            <span>一分钟获得个性化课程推荐</span>
          </div>
        </div>
      </div>
    </div>
    <div class="course-list">
      <div class="list max-1152 clearfix">
        <a class="item free" :href="`#/learn?id=${i.id}`" target="_blank" v-for="i in tableData" :key="i.name">
          <div class="img" :style="{'backgroundImage':`url(${i.imagesmall})`}"></div>
          <p class="title ellipsis2">{{i.name}}</p>
          <p class="one">{{i.level}} · {{i.learnpeple}}人报名</p>
          <p class="two clearfix"><span class="l">免费</span><span class="star r"><i class="icon imv2-star-o"></i><i class="txt">收藏</i></span></p>
        </a>
      </div>
      <div class="page">
        <span class="disabled_page">首页</span><span class="disabled_page">上一页</span>
        <a v-for="i in totla" :key="i" :class="{ active: activetotla == i }" href="javascript:void(0)" class="text-page-tag">{{ i }}</a>
        <a href="#/course/list?page=2">下一页</a>
        <a href="#/course/list?page=21">尾页</a>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../ulti/ajax";
import {setUser} from "../ulti/auth";
export default {
  data() {
    return {
      // 方向
      fangxiang: [],
      fxactive: "全部",
      // 分类
      classFL: [],
      FLactive: "全部",
      navlist: [],
      // 难度列表
      nd: ["全部", "初级", "中级","高级"],
      ndActive: "全部",
      // 请求总数据
      respdata: {},
      totla: 0,
      activetotla: 1,
      tableData: [],
      search:'',

      selectType:{},
      selectTypesub:{},
      selectNandu:0,
    };
  },
  watch: {
    // 方向切换(分类1)
    fxactive(v) {

      this.selectType=v;
      //console.log("方向切换："+v.name+"---id:"+v.id);

      if (v.name == "全部") {
        this.classFL = this.navlist[0].coursetypesubs.map((e) => e);
      } else {
        this.navlist.map((e) => {
          if (e.name == v.name) {
            this.classFL = e.coursetypesubs.map((e) => e);
          }
        });
      }
      this.FLactive = {name:"全部",id:-1};
      this.renderData();
    },
    FLactive(v) {
      this.renderData();
    },

    ndActive(v) {
     this.ndActive=v;
      this.renderData();
    },

  },
  methods: {
    renderData() {

      this.selectTypesub=this.FLactive;

      console.log("selectType:"+JSON.stringify(this.selectType));
      console.log("selectTypesub:"+JSON.stringify(this.selectTypesub));

      //初级 中级，高级
      if(this.ndActive=='初级'){
        this.selectNandu=1;
      }else if(this.ndActive=='中级'){
        this.selectNandu=2;
      }else if(this.ndActive=='高级'){
        this.selectNandu=3;
      }else if(this.ndActive=='全部'){
        this.selectNandu=-1;
      }


      //console.log("难度:"+JSON.stringify(this.selectNandu));
      //console.log("search:"+this.search);


      let typeid1=this.selectType.id;
      let typeid2=this.selectTypesub.id;
      let pageSize=10;
      let pageNum=1
      let search='';
      api.getCourseListPage(typeid1,typeid2,pageNum,pageSize,search).then(response => {

       // console.log("1111:"+JSON.stringify(response))

        if (response.data.code == 200) {
          this.tableData = response.data.rows;
          console.log("222:"+JSON.stringify(this.tableData))
          //this.total = response.total;
        }
      });
     /* if (this.fxactive == "全部" && this.FLactive == "全部") {
        this.list = this.allData.map(e=>e);
      } else {
         this.list  = this.allData
          .map((e) => {
            if (e.coursetypeid == coursetypeid + 1) {
              return e;
            } else {
              return "";
            }
          })
          .filter((e) => e);
      }*/
    },
  },
  async created() {
    // 获取banner 列表数据
    this.navlist = (await api.getIndexList()).data.rows;
    this.fangxiang = this.navlist.map((e) => e);
    this.fangxiang.unshift({name:"全部",id:-1});


    document.title='p8课堂';

   // console.log("this.fangxiang:"+this.fangxiang)

  //  console.log("this.navlist:"+JSON.stringify(this.navlist))

    // 获取所有数据
    this.respdata = await api.getIndexAllData();
    //console.log(this.respdata.data)
    this.allData = this.respdata.data.rows;
   // console.log(this.respdata.data)
    this.fxactive = this.fangxiang[0];

    this.classFL = this.navlist[0].coursetypesubs.map((e) => e);


    this.renderData();
  },
};
</script>

<style>
@import url("../assets/css/list.css");
.main{
  width: 1300px;
  margin:0 auto;
}
.max-1152 {
   width: 1300px;
  margin:0 auto;
  padding:0;
}

.course-type .max-1152{
  padding:20px 5px;
}
@media screen and (min-width: 1300px) {
.main{
  width: 1300px;
  margin:0 auto;
}
.max-1152 {
   width: 1300px;
  margin:0 auto;
  padding:0;
}
}
</style>
